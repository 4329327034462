// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-html-js": () => import("/Users/brendan/Projects/jay/jay-blog/src/templates/html.js" /* webpackChunkName: "component---src-templates-html-js" */),
  "component---src-templates-page-js": () => import("/Users/brendan/Projects/jay/jay-blog/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("/Users/brendan/Projects/jay/jay-blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-old-js": () => import("/Users/brendan/Projects/jay/jay-blog/src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/brendan/Projects/jay/jay-blog/.cache/data.json")

